<template>
  <div class="lock-list">
    <app-list :opt="opt" @get="onGet" ref="myList"></app-list>
    <el-dialog title="编辑安装位置" :visible.sync="dialogVisible" :close-on-click-modal="false">
      <el-form :model="dialogList" :rules="rules" ref="ruleForm" label-width="100px">
        <el-form-item label="设备类型：">
          <span v-text="dialogList.Name"></span>
        </el-form-item>
        <el-form-item label="MAC：">
          <span v-text="dialogList.mac"></span>
        </el-form-item>
        <el-form-item label="IP：">
          <span v-text="dialogList.ip"></span>
        </el-form-item>
        <el-form-item label="绑定房源：">
          <el-cascader :options="selectList" :props="props" :separator="'/'" ref="editCascader"
            v-model="dialogList.apartmentID" filterable></el-cascader>
        </el-form-item>
        <el-form-item label="安装位置：">
          <el-input type="textarea" class="textarea" v-model="dialogList.location" placeholder="请输入安装位置" show-word-limit
            maxlength="200"></el-input>
        </el-form-item>
        <div style="text-align: center;">
          <el-button @click="onRester()">取消</el-button>
          <el-button type="primary" @click="onSubmit()">确定</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
  let validMAC = (rule, value, callback) => {
    if (value == "" || value == undefined) {
      callback();
    } else {
      //支持输入大小写字母，数字，限制1~32位字符
      let reg = /^[A-z0-9]{1,32}$/;
      if (!reg.test(value)) {
        callback(new Error("MAC格式不对"));
      } else {
        callback();
      }
    }
  };
  export default {
    data() {
      let _this = this;
      return {
        dialogVisible: false,
        props: {
          multiple: true
        },
        selectList: [],
        dialogList: {
          id: "",
          Name: "",
          mac: "",
          ip: "",
          apartmentID: [],
          location: ""
        },
        opt: {
          title: "电子门禁列表",
          search: [{
              key: "mac",
              label: "MAC",
              maxlength: 32,
              rules: [{
                validator: validMAC,
                trigger: ['blur']
              }]
            },
            {
              key: "location",
              label: "安装位置",
              maxlength: 200,
            },

          ],
          columns: [{
              label: "MAC",
              key: "mac"
            },
            {
              label: "设备类型",
              key: "Name"
            },
            // { label: "IP地址", key: "ip" },
            {
              label: "绑定房源",
              key: "apartmentNameStr"
            },
            {
              label: "安装位置",
              key: "locationStr"
            },
            {
              label: "最近一次使用时间",
              key: "lastUseTimeStr"
            },
            {
              label: "在线状态",
              key: "stateStr"
            },
            {
              label: "电量",
              key: "batteryStr"
            },
            {
              label: "ICCID",
              key: "iccid"
            },
            {
              label: "借用状态",
              key: "borrowStateStr"
            },
            {
              label: "操作",
              key: "action",
              type: "action",
              opt: {
                list: [{
                  label: "查看记录",
                  on(row) {
                    // let locationStr = '';
                    // if (row.roomKeyRels && row.roomKeyRels.length > 0) {
                    //   if (row.roomKeyRels[0].buildingNo) {
                    //     locationStr = row.roomKeyRels[0].apartmentName + row
                    //       .roomKeyRels[0].buildingNo + '栋';
                    //   }
                    //   if (row.roomKeyRels[0].targetType == 'public_area') {
                    //     locationStr = row.roomKeyRels[0].apartmentName + '公区';
                    //   }
                    //   if (row.roomKeyRels.length > 1) {
                    //     locationStr = locationStr + '...'
                    //   }
                    // }
                    _this.$router.push({
                      path: "/main/electronicAccessControl/log/" + row.id,
                      query: {
                        ip: row.ip,
                        // location: locationStr,
                        location: row.location,
                        stateStr: row.stateStr,
                        mac: row.mac,
                        firmwareVersion: row.firmwareVersion,
                        type: row.type,
                      }
                    });
                    // _this.$router.push("/main/electronicAccessControl/log/" + row.id);
                  }
                }]
              }
            }
          ],
          buttons: [{
            type: 1,
            on(data) {
              // _this.$router.push("/main/apartment/detail/edit/" + data.id);
              let row = data.data;
              console.log(row);
              if (_this.$refs.ruleForm) {
                _this.$refs.ruleForm.resetFields();
              }
              _this.dialogVisible = true;
              _this.dialogList.Name = row.Name;
              _this.dialogList.id = row.id;
              _this.dialogList.keyId = row.keyId;
              _this.dialogList.mac = row.mac;
              _this.dialogList.ip = row.ip;
              _this.dialogList.apartmentID = row.roomKeyRels ? row.roomKeyRels : [];
              _this.dialogList.location = row.location;
              // _this.dialogList.publicAreaCode = row.publicAreaCode;
              // 以下代码用于调整楼栋和房源顺序排序，否则cascader组件的删除会有异常。
              let tempList = {},
                cascaderList = [];
              row.roomKeyRels.forEach(item => {
                tempList[item.apartmentId] = tempList[item.apartmentId] ?
                  tempList[item.apartmentId] : [];
                tempList[item.apartmentId].push(item);
              });
              for (var k in tempList) {
                tempList[k] = tempList[k].sort((a, b) => {
                  return a.buildingNo - b.buildingNo;
                });
                cascaderList = [...cascaderList, ...tempList[k]];
              }
              _this.dialogList.apartmentID = [];
              cascaderList.forEach((item, index) => {
                // if(item.buildingNo=='-1'){
                //   item.buildingNo='PublicDistrict';
                // }
                if (item.targetType == 'public_area' && item.publicAreaCode) //选择了公区
                {
                  _this.dialogList.publicAreaCode = item.publicAreaCode;
                  _this.dialogList.apartmentID.push([
                    item.apartmentId,
                    `${item.apartmentId}-PublicDistrict`
                  ]);
                } else {
                  _this.dialogList.apartmentID.push([
                    item.apartmentId,
                    `${item.apartmentId}-${item.buildingNo}`
                  ]);
                }

              });
            }
          }, ]
        },
        rules: {
          // apartmentID: [{ required: true, message: "请选择绑定房源(选到栋)", trigger: "blur" }],
        }
      };
    },
    created() {
      console.log("lock-list created!!");
      // this.getApartmentList();
    },
    activated() {
      console.log("lock-list activated!!");
      this.getApartmentList();
    },
    deactivated() {
      this.dialogVisible = false;
    },
    methods: {
      onGet(opt) {
        let dto = {
          pageNum: opt.skip,
          pageSize: opt.limit,
          ...opt.searchForm
        };
        this.post("device-service/L2plus/queryL2plusPage", dto, {
          isUseResponse: true
        }).then(res => {
          for (let i = 0; i < res.data.data.length; i++) {
            let item = res.data.data[i];
            item.Name = ["蓝牙网关", "W7", "蓝牙公钥(L2)", "电子门禁(L2+)", "W8"][item.type - 1];
            item.stateStr = item.onlineState ? ["离线", "在线"][item.onlineState] : '离线';
            item.borrowStateStr = item.borrowState ? ["未借出", "已借出"][item.borrowState] : '未借出';
            item.action = [true, true];
            item.locationStr = item.location ? item.location : '--';
            item.lastUseTimeStr = item.lastUseTime ? item.lastUseTime : '--';
            let arrStr = [];
            if (item.roomKeyRels) {
              item.roomKeyRels.forEach(it => {
                if (arrStr.indexOf(it.apartmentName) == -1) {
                  arrStr.push(it.apartmentName)
                }
              })
            }
            item.batteryStr = item.battery ? item.battery + '%' : '--';
            item.apartmentNameStr = arrStr.join('、');
          }
          opt.cb(res.data);
        });
      },
      //获取房源列表
      getApartmentList() {
        var dto = {
          pageNumber: "1",
          pageSize: "99999",
          landlordId: this.cache.getLS("userInfo")["roleType"] == "1" ?
            null : this.cache.getLS("userInfo")["userId"]
        };
        this.post("landlord-service/apartment/queryPagesApartment", dto).then(
          res => {
            let tempListArray = [];
            res.forEach((item, index) => {
              item.label = item.name;
              item.value = item.id;
              tempListArray.push({
                label: item.label,
                value: item.value,
                children: []
              });
              // item.buildingNoList=['1']
              tempListArray[index]["children"] = [{
                label: '公区',
                value: `${item.value}-PublicDistrict`
              }]
              item.buildingList.forEach(data => {
                tempListArray[index]["children"].push({
                  label: data.buildName,
                  value: `${item.value}-${data.buildCode}`
                });
              });
            });
            this.selectList = tempListArray;

          }
        );
      },
      onRester() {
        this.dialogVisible = false;
      },
      onSubmit() {
        console.log(this.dialogList);
        // let reg = /^[a-zA-Z0-9\u4e00-\u9fa5]{1,64}$/;
        // if (this.dialogList.location && !reg.test(this.dialogList.location)) {
        //   return this.$message({
        //     message: "安装位置格式错误！",
        //     type: "warning"
        //   });
        // }
        this.$refs["ruleForm"].validate(valid => {
          if (valid) {
            console.log(this.dialogList.apartmentID);
            console.log(this.$refs.editCascader.getCheckedNodes());
            let landlordAuthRelList = [];
            console.log('this.dialogList.publicAreaCode', this.dialogList.publicAreaCode);
            this.dialogList.apartmentID.forEach(item => {
              let obj = {};
              if (item[1].split("-")[1] == 'PublicDistrict') //选择了公区
              {
                obj = {
                  apartmentId: item[0],
                  targetType: 'public_area',
                  publicAreaCode: this.dialogList.publicAreaCode ? this.dialogList.publicAreaCode : ''
                }
              } else {
                obj = {
                  apartmentId: item[0],
                  buildingNo: item[1].split("-")[1]
                }
              }
              landlordAuthRelList.push(obj);
            });
            var dto = {
              l2plusId: this.dialogList.id,
              keyId: this.dialogList.keyId,
              mac: this.dialogList.mac,
              ip: this.dialogList.ip,
              roomKeyRels: landlordAuthRelList,
              location: this.dialogList.location
            };
            console.log(dto)
            this.post("device-service/L2plus/edit", dto).then(res => {
              this.$message({
                message: "编辑安装位置成功!",
                type: "success"
              });
              this.$refs.myList.get();
              this.dialogVisible = false;
            });
          }
        })
      },
    }
  };
</script>
<style lang="scss">
  .alertTiltes {
    .ul_div {
      display: flex;
      line-height: 40px;
      font-family: "微软雅黑";
      font-weight: 400;
      font-style: normal;
      font-size: 14px;
      color: #666666;
      margin-bottom: 10px;
    }

    .ul_li_one {
      width: 85px;
      height: 40px;
      text-align: right;
    }

    .ul_li_scond {
      width: 160px;
      height: 40px;
      text-align: center;
    }

    .ul_li_thrwe {
      width: 310px;
      text-align: left;

      .img_div {
        width: 70px;
        height: 70px;
        float: left;
        border: 1px solid #dcdfe6;
        margin-right: 5px;
        margin-bottom: 5px;

        img {
          width: 100%;
          height: 100%;
          vertical-align: top;
        }
      }
    }

    .textarea {
      width: 310px;
      height: 80px;
      border-color: #dcdfe6;
      color: #303133;

      textarea {
        width: 100%;
        height: 100%;
        resize: none;
      }
    }
  }

  // 解决抖动问题，浏览器缩小才会出现
  .el-cascader .el-input {
    padding-bottom: 2px;
  }

  // .el-cascader{
  //   height: auto!important;
  // }
  // .el-cascader .el-input__inner{
  //   height: 100%!important;
  // }
  // .el-cascader-menu__wrap {
  //     max-height: 300px;
  //     overflow: scroll;
  //   } 
</style>
<style scoped>
  /* .el-cascader-menu {
  height: 300px!important;
} */
</style>